.all-container {
  /*width: 400px;*/
  margin: 0 auto;
  margin-top: 10px;
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid;
  border-color :rgba(var(--gray70), 1);
  padding: 20px;
}

/* 탭 스타일 */
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  border: none;
  background: none;
  font-size: 15px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabs .active {
  border-bottom: 2px solid var(--primary-navy800);
  font-weight: bold;
}

/* 인풋 박스 */
.input-section input {
  flex: 2;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.display-amount {
  margin-top: 5px;
  font-size: 14px;
  color: gray;
  text-align: right;
}

/* 금액 추가 버튼 */
.amount-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.amount-buttons button {
  flex: 1;
  padding: 5px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: small;
}

/* 수익률 버튼 정렬 */
.yield-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.yield-buttons button {
  flex: 1;
  padding: 5px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: small;
}

.yield-buttons .active {
  background-color: #c0c2c0;
  color: white;
}

/* 배당 주기 버튼 */
.payout-buttons {
  /*display: flex;
  gap: 10px;
  margin-top: 5px;
  */
    display: table;
    float: none;
    width: 100%;
    table-layout: fixed;

}

.payout-buttons .empty {
	display: table-cell;
	width: auto;
	padding: 0;
	border: 0;
}
.payout-buttons .empty:before {
	right: -1px;
	border-width: 1px;
}
.payout-buttons .empty + a:before {
	-wekbit-border-radius: 0;
	border-radius: 0;
}
.payout-buttons .empty + a.on:before {
	border-width: 1px;
}
.payout-buttons .empty.last:before {
	right: 0;
	border-radius: 0 8px 8px 0;
}

.payout-buttons a + a, .payout-buttons .empty + a {
	border-left: 0;
	margin-left: -1px;
}

.payout-buttons button {
  flex: 1 1;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size : small;
  width : 25%;
  height : 40px;

}

.payout-buttons .active {
  background-color: #feed9224;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  font-weight: bold;
}

/* 초기화, 계산하기 버튼 */
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.actions .reset {
  background-color: white;
  color: rgba(var(--gray40), 1);
  padding: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 5px;
  flex: 1;
  margin-right: 10px;
}

.actions .calculate {
  /*background-color: #fde14f;*/
  background-color: var(--color-primary);
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  flex: 1;
}

/* 결과 표시 */
.result {
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 5px;
}

.result .highlight {
  color: var(--color-primary-244);
  font-weight: bold;
}

.result-summary {
  border-bottom : 1px solid rgba(var(--gray60), 1);
  line-height : 28px;
  padding-bottom : 10px;
}

.result-tax {
  padding-top : 10px;
  padding-bottom : 10px;
  font-size : smaller;
}
.result-total {
  border-top : 1px solid rgba(var(--gray60), 1);
  border-bottom : 1px solid rgba(var(--gray60), 1);
  padding-top : 5px;
  padding-bottom : 5px;
  font-size : smaller;
}

/*my add*/
.item-container {
  display: flex;
  margin-top: 20px;
}
.item-container .item-title{
  width: 25%;
  margin-top: 5px;
  font-size: medium;
  margin-right: 5px;
}
.item-container .item-input{
  width: 75%;
}

input{
	width: 90%;
}

.item-container .item-input .input_bx {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

.item-container .item-input .input_bx input {
	display: block;
	position: relative;
	width: 100%;
	padding: 0 30px 5px 0;
	border-radius: 0;
	font-size: 1.5rem;
	line-height: 2.1rem;
	color: #5c5c5c;
	font-weight: 400;
	text-align: right;
	background: none;
	border: 0;
	border-bottom: 1px solid #ccc;
}

/*
.item-container .item-input .input_bx input[type=number]::-webkit-input-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input[type=number]::-moz-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input[type=number]:-ms-input-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input[type=number]::-ms-input-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input[type=number]::placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input[type=number]:focus {
	outline: none;
	border-color: #fde14f;
}

.item-container .item-input .input_bx input[type=number]::-webkit-outer-spin-button,
.item-container .item-input .input_bx input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
  margin: 0;
}
*/
.item-container .item-input .input_bx input::-webkit-input-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input::-moz-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input::-ms-input-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input::-ms-input-placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input::placeholder {
	color: #C5C5C7;
	color: rgba(var(--gray60), 1);
	font-weight: 400;
}
.item-container .item-input .input_bx input:focus {
	outline: none;
	border-color: #fde14f;
}

.item-container .item-input .input_bx input::-webkit-outer-spin-button,
.item-container .item-input .input_bx input::-webkit-inner-spin-button {
	-webkit-appearance: none;
  margin: 0;
}

.item-container .item-input .input_bx .unit {
	display: block;
	position: absolute;
	top: 0;
	right: 4px;
	font-size: 1.3rem;
	line-height: 2.1rem;
	color: #717171;
}

:root {
  --gray100: 255,255,255;
  --gray99: 250,250,251;
  --gray95: 247,247,249;
  --gray90: 240,240,243;
  --gray80: 233,233,236;
  --gray70: 217,217,220;
  --gray60: 197,197,199;
  --gray50: 147,147,150;
  --gray40: 119,119,122;
  --gray30: 85,85,88;
  --gray20: 68,68,71;
  --gray10: 34,34,37;
  --gray0: 0,0,0;
  --bgElevatedSurface: 255,255,255;
  --searchGreen: 3,170,90;
  --blueLink: 12,67,183;
  --visitedLink: 153,32,155;
  --emphasis: 252,76,78;
  --bgBlock: 255,255,255;
  --bgBase: 245,245,248;
  --bgThumb: 0,0,0;
  --bgSurface: 255,255,255;

  --primary-yellow100: #f1b13c;
  --secondary-brightRed100: #ff453a;
  --secondary-brightRed50: #892e2e;
  --secondary-brightRed15: #361e26;
  --secondary-darkRed100: #c1464e;
  --secondary-darkRed50: #6a2e38;
  --secondary-darkRed15: #2d1e29;
  --secondary-emerald100: #45c9ab;
  --secondary-emerald50: #2c7066;
  --secondary-emerald15: #1b3237;
  --secondary-green100: #31e038;
  --secondary-green50: #227c2d;
  --secondary-green15: #183525;
  --secondary-blue100: #0383fa;
  --secondary-blue50: #0b4d8e;
  --secondary-blue15: #112742;
  --secondary-black100: #000000;
  --secondary-black80: #000000cc;
  --secondary-white100: #ffffff;
  --secondary-white80: #ffffffcc;
  --chart-1: #079992;
  --chart-2: #0a3d62;
  --chart-3: #0c2461;
  --chart-4: #b71540;
  --chart-5: #e58e26;
  --chart-6: #38ada9;
  --chart-7: #3c6382;
  --chart-8: #187abd;
  --chart-9: #e55039;
  --chart-10: #f6b93b;
  --chart-11: #78e08f;
  --chart-12: #60a3bc;
  --chart-13: #fad390;
  --chart-14: #6a89cc;
  --chart-15: #b8e994;
  --chart-16: #a0a0a0;
  --gradient-greenBlue: linear-gradient(#2db48b, #1B67BF);
  --gradient-purpleBlue: linear-gradient(#6c2ab1, #399ede);
  --gradient-pinkRed: linear-gradient(#A540F9, #ba1641);
  --gradient-OrangeYellow: linear-gradient(#CA4938, #e3b456);
  --gradient-yellowOrange: linear-gradient(#e3b456, #CA4938);
  --gradient-brightDarkNavy: linear-gradient(#1f273a, #1B1F2C);
  --gradient-tangerineYellow: linear-gradient(#fb992a, #FDC954);
  --gradientColor-green: #2db48b;
  --gradientColor-darkBlue: #1B67BF;
  --gradientColor-purple: #6c2ab1;
  --gradientColor-blue: #399ede;
  --gradientColor-pink: #A540F9;
  --gradientColor-red: #ba1641;
  --gradientColor-darkYellow: #e3b456;
  --gradientColor-orange: #CA4938;
  --gradientColor-brightDark: #1f273a;
  --gradientColor-navy: #1B1F2C;
  --gradientColor-tangerine: #fb992a;
  --gradientColor-lightYellow: #FDC954;
}

.ls15 {
  letter-spacing:15px;
}
.ls13 {
  letter-spacing:13px;
}
.ls10 {
  letter-spacing:10px;
}
.ls5 {
  letter-spacing:5px;
}