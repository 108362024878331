body {
    background-color: #ffffff;
    font-size: medium;
    color: var(--primary-navy800);
}

:root {
    --color-primary-rgb: 241, 177, 60;
    --color-primary: rgb(255, 216, 59);
    --color-primary-a1: rgb(241, 177, 60, 0.1);
    --color-primary-a2: rgb(241, 177, 60, 0.2);
    --color-primary-a5: rgb(241, 177, 60, 0.5);
    --color-primary-244: rgb(255, 185, 45);
    --color-primary-20: rgb(63, 54, 39);
    --color-secondary: rgba(72, 158, 177, 0.61);
    --color-red-193: rgb(193, 79, 78);
    --color-red-193-a100: rgba(193, 70, 78, 1);
    --color-red-193-a17: rgba(193, 70, 78, 0.17);
    --color-red-202: rgb(202, 52, 31);
    --color-red-210: rgb(210, 103, 99);
    --color-red-255: rgb(255, 0, 0);
    --color-red-255-58: rgb(255, 69, 58);
    --color-green-50: rgb(50, 215, 75);
    --color-green-69: rgb(69, 201, 171);
    --color-green-69-a17: rgba(69, 201, 171, 0.17);
    --color-green-76: rgb(76, 175, 80);
    --color-green-82: rgba(82, 122, 104, 1);
    --color-normal-147: rgba(147, 147, 147, 1);
    --color-normal-106-a2: rgba(106, 106, 106, 0.2);
    --color-kakao: rgb(254, 218, 49);
    --color-facebook: rgb(24, 119, 242);
    --color-blue-97: rgba(97, 177, 246, 1);
    --color-blue-97-a20: rgba(97, 177, 246, 0.2);
    --color-primary-yellow: rgb(52, 46, 38);
    --color-yellow-238: rgb(238, 207, 70);
    --color-black: rgb(0, 0, 0);
    --color-black-11: rgb(11, 12, 20);
    --color-black-40: rgb(40, 40, 40);
    --color-black-a6: rgba(0, 0, 0, 0.6);
    --color-black-a75: rgba(0, 0, 0, 0.75);
    --color-gray-14-28: rgb(14, 20, 28);
    --color-gray-14: rgb(14, 18, 29);
    --color-gray-19-a75: rgba(19, 23, 34, 0.75);
    --color-gray-19: rgb(19, 23, 34);
    --color-gray-21: rgb(21, 29, 45);
    --color-gray-22: rgb(22, 29, 44);
    --color-gray-23: rgb(23, 25, 30);
    --color-gray-24: rgba(24, 28, 41, 1);
    --color-gray-27-40: rgb(27, 31, 40);
    --color-gray-27-a30: rgba(27, 31, 44, 0.3);
    --color-gray-27: rgba(27, 31, 44);
    --color-gray-28: rgb(28, 32, 42);
    --color-gray-29: rgb(29, 34, 55);
    --color-gray-29-47: rgb(29, 33, 47);
    --color-gray-31: rgb(31, 39, 58);
    --color-gray-31-43: rgb(31, 33, 43);
    --color-gray-32: rgb(32, 38, 55);

    --primary-yellow100: #f1b13c;
    --primary-yellow50: #82642f;
    --primary-yellow20: #3f3627;
    --primary-yellow15: #342e26;
    --primary-yellow10: #292625;
    --primary-navy900: #0f131c;
    --primary-navy800: #131722;
    --primary-navy700: #1b1f2c;
    --primary-navy600: #262936;
    --primary-navy500: #30333f;
    --primary-navy400: #64666e;
    --grayscale-gray900: #212121;
    --grayscale-gray800: #505050;
    --grayscale-gray600: #757575;
    --grayscale-gray500: #9e9e9e;
    --grayscale-gray400: #bdbdbd;
    --grayscale-gray50: #fafafa;
    --secondary-brightRed100: #ff453a;
    --secondary-brightRed50: #892e2e;
    --secondary-brightRed15: #361e26;
    --secondary-darkRed100: #c1464e;
    --secondary-darkRed50: #6a2e38;
    --secondary-darkRed15: #2d1e29;
    --secondary-emerald100: #45c9ab;
    --secondary-emerald50: #2c7066;
    --secondary-emerald15: #1b3237;
    --secondary-green100: #31e038;
    --secondary-green50: #227c2d;
    --secondary-green15: #183525;
    --secondary-blue100: #0383fa;
    --secondary-blue50: #0b4d8e;
    --secondary-blue15: #112742;
    --secondary-black100: #000000;
    --secondary-black80: #000000cc;
    --secondary-white100: #ffffff;
    --secondary-white80: #ffffffcc;
    --chart-1: #079992;
    --chart-2: #0a3d62;
    --chart-3: #0c2461;
    --chart-4: #b71540;
    --chart-5: #e58e26;
    --chart-6: #38ada9;
    --chart-7: #3c6382;
    --chart-8: #187abd;
    --chart-9: #e55039;
    --chart-10: #f6b93b;
    --chart-11: #78e08f;
    --chart-12: #60a3bc;
    --chart-13: #fad390;
    --chart-14: #6a89cc;
    --chart-15: #b8e994;
    --chart-16: #a0a0a0;
    --gradient-greenBlue: linear-gradient(#2db48b, #1B67BF);
    --gradient-purpleBlue: linear-gradient(#6c2ab1, #399ede);
    --gradient-pinkRed: linear-gradient(#A540F9, #ba1641);
    --gradient-OrangeYellow: linear-gradient(#CA4938, #e3b456);
    --gradient-yellowOrange: linear-gradient(#e3b456, #CA4938);
    --gradient-brightDarkNavy: linear-gradient(#1f273a, #1B1F2C);
    --gradient-tangerineYellow: linear-gradient(#fb992a, #FDC954);
    --gradientColor-green: #2db48b;
    --gradientColor-darkBlue: #1B67BF;
    --gradientColor-purple: #6c2ab1;
    --gradientColor-blue: #399ede;
    --gradientColor-pink: #A540F9;
    --gradientColor-red: #ba1641;
    --gradientColor-darkYellow: #e3b456;
    --gradientColor-orange: #CA4938;
    --gradientColor-brightDark: #1f273a;
    --gradientColor-navy: #1B1F2C;
    --gradientColor-tangerine: #fb992a;
    --gradientColor-lightYellow: #FDC954;
}